<template>
  <div>
    <CreateModalForm
      ref="form"
      v-on="$listeners"
      :formData="form"
      :removeFromSendData="['place.name', 'place.is_favored']"
      :title="$t('app.admin.newsFeeds.create.title')"
      :successMessage="$t('app.admin.newsFeeds.create.successMessage')"
      url="/api/news-feeds"
      #default="{ formData }"
    >
      <CreateFormPart :model="formData"></CreateFormPart>
    </CreateModalForm>
  </div>
</template>

<script>
import ModalFormMixin from "@/mixins/ModalFormMixin.js";
import CreateModalForm from "@/components/CreateModalForm.vue";
import CreateFormPart from "./CreateFormPart.vue";
export default {
  mixins: [ModalFormMixin],
  components: { CreateFormPart, CreateModalForm },
  data() {
    return {
      form: {
        publisher: null,
        url: null,
        feed_type: "unknown",
        title_filter: null,
        title_sub_pattern: null,
        title_sub_repl: null,
        place: null,
      },
    };
  },
};
</script>
